<form
  *ngIf="profileForm"
  [id]="formId"
  [formGroup]="profileForm"
  (submit)="submitForm(profileForm?.value)"
  class="d-flex flex-column"
>
  <!-- DEBUG   -->
  <!-- <pre style="color: aquamarine"> {{ profileForm?.value | json }} </pre>
  <pre style="color: aquamarine">
 VALID?: {{ this.profileForm.valid && this.isLocationFormValid | json }} </pre
  > -->

  <app-media-storage-summary showNonActive="true" showButtons="isSuperAdmin"></app-media-storage-summary>
  <div class="">
    <h5 class="mb-3 text-uppercase bg-light p-2">
      {{ 'PROFILE_INFO' | translate }}
    </h5>
    <div class="row">
      <!-- TODO make it possible to upload a logo here -->
      <!-- <div class="gridProfilePic">
        <img
          src="assets/images/placeholder-image.jpg"
          alt="{{ 'PROFILE_IMAGE' | translate }}"
          class="rounded-circle avatar-xl img-thumbnail"
        />
      </div> -->
      <div class="col-12 col-sm-6">
        <div class="mb-3">
          <label class="form-label" for="profileName"
            >{{ 'PROFILE_NAME' | translate }}*</label
          >
          <span
            class="error"
            *ngIf="
              profileForm &&
              profileForm.controls['name'] &&
              !profileForm.controls['name'].pristine &&
              profileForm.controls['name'].hasError('required')
            "
          >
            {{ 'NAME_IS_REQUIRED' | translate }}
          </span>
          <input
            type="text"
            name="profileName"
            class="form-control form-control-dark"
            formControlName="name"
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="description">{{
            'TAGLINE_OR_DESCRIPTION' | translate
          }}</label>
          <input
            type="text"
            name="description"
            class="form-control form-control-dark"
            formControlName="description"
          />
        </div>
      </div>

      <div class="col-12 col-sm-6">
        <div class="mb-3">
          <label class="form-label" for="owner"
            >{{ 'OWNER' | translate }}*</label
          >
          <label
            class="text-info form-label"
            *ngIf="!profile.owner?.active"
            for="owner"
          >
            {{ 'OWNER_HAS_BEEN_DEACTIVATED' | translate }}
          </label>

          <span
            class="error"
            *ngIf="
              profileForm &&
              profileForm.controls['ownerId'] &&
              !profileForm.controls['ownerId'].pristine &&
              profileForm.controls['ownerId'].hasError('required')
            "
          >
            {{ 'OWNER_IS_REQUIRED' | translate }}
          </span>

          <select
            name="owner"
            class="form-select form-select-dark"
            formControlName="ownerId"
          >
            <option
              disabled
              [ngValue]="null"
              selected
              *ngIf="!profile.owner?.active"
            >
              {{ 'SELECT_NEW_OWNER' | translate }}
            </option>

            <option
              [ngValue]="user.id"
              *ngFor="let user of activeUsers"
              [selected]="profile.owner?.id === user.id"
            >
              {{ user.displayName }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <div *ngIf="showOrganizationDropDown">
            <label class="form-label" for="organizationUid"
              >{{ 'ORGANIZATION' | translate }}*</label
            >
            <label
              class="text-info form-label"
              *ngIf="
                this.profile.organization && !this.profile.organization?.active
              "
              for="organization"
            >
              {{ 'ORGANIZATION_HAS_BEEN_DELETED' | translate }}
            </label>
            <span
              class="error"
              *ngIf="
                profileForm &&
                profileForm.controls['organizationUid'] &&
                !profileForm.controls['organizationUid'].pristine &&
                profileForm.controls['organizationUid'].hasError('required')
              "
            >
              {{ 'ORGANIZATION_IS_REQUIRED' | translate }}
            </span>

            <app-organization-selection
              [profileId]="profile?.id"
              [selectedOrganizationId]="profile?.organization?.id"
              (selectionChange)="setOrganization($event)"
              (loaderMessage)="loaderMessage = $event"
              (loading)="loading = $event"
            ></app-organization-selection>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="gridCompanyInfo">
    <div class="row py-3">
      <div class="col-12">
        <ul class="nav nav-pills navtab-bg">
          <li class="nav-item">
            <a
              (click)="currentTab = tabs.PROFILE_SETTINGS"
              data-toggle="tab"
              aria-expanded="false"
              [class.active]="currentTab === tabs.PROFILE_SETTINGS"
              class="nav-link"
            >
              {{ 'PROFILE_SETTINGS' | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a
              (click)="currentTab = tabs.COMPANY_MAIN"
              data-toggle="tab"
              aria-expanded="true"
              [class.active]="currentTab === tabs.COMPANY_MAIN"
              class="nav-link"
            >
              {{ 'COMPANY_INFO' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <ng-container *ngIf="currentTab === tabs.COMPANY_MAIN">
      <div class="col-12 form-group">
        <div *ngIf="profile?.id && !isCreate">
          <label for="location">{{ 'LOCATION' | translate }}</label>
          <span
            class="error"
            *ngIf="
              locationForm &&
              locationForm.controls['id'] &&
              !locationForm.controls['id'].pristine &&
              locationForm.controls['id'].hasError('required')
            "
          >
            {{ 'LOCATION_IS_REQUIRED' | translate }}
          </span>
          <app-location-tab-selection
            [profileId]="profile.id"
            [defaultLocationId]="profile.defaultLocation.id"
            [parentFormGroup]="profileForm"
          ></app-location-tab-selection>
        </div>

        <div *ngIf="isCreate">
          <app-location-form
            [parentFormGroup]="profileForm"
            [isCreate]="true"
            (valid)="isLocationFormValid = $event"
          ></app-location-form>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentTab === tabs.PROFILE_SETTINGS">
      <div class="row p-3">
        <h4>
          {{ 'PROFILE_SETTINGS_WORKSPACE_HEADER' | translate }}
        </h4>
        <table class="w-auto table">
          <tbody>
            <tr>
              <td>
                <span ngbTooltip="{{ 'PROFILE_TIMEZONE_TT' | translate }}">
                  {{ 'PROFILE_TIMEZONE' | translate }}
                </span>
              </td>
              <td style="min-width: 10rem">
                <designage-typeahead
                  class="position-relative"
                  [formSizeSmall]="true"
                  [items]="timezones"
                  [defaultItem]="profileTimezone()"
                  [placeHolderText]="profileTimezone()"
                  [noMatchedItemText]="noMatchedItemText"
                  (selectItem)="updateTimezone($event)"
                  [control]="profileForm?.controls.timezone"
                ></designage-typeahead>
              </td>
              <td>
                <span class="badge bg-black mx-1">
                  GMT {{ timezonePreview() }}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  {{ 'PROFILE_DATE_FORMAT' | translate }}
                </span>
              </td>
              <td>
                <select
                  class="form-select form-select-sm form-select-dark"
                  (change)="updateProfileDateFormat($event)"
                  [(ngModel)]="profileDateFormat"
                  formControlName="dateFormat"
                >
                  <!-- [selected]="dateFormat.name == profileDateFormat.name" -->
                  <option
                    *ngFor="let dateFormat of dateFormats; let i = index"
                    [ngValue]="dateFormat"
                  >
                    {{ dateFormat.name }}
                  </option>
                </select>
              </td>
              <td>
                <span class="badge bg-black mx-1">
                  {{
                    dateNow
                      | date
                        : profileDateFormat.short
                        : undefined
                        : timezoneOffset()
                  }}
                </span>
                <span class="badge bg-black mx-1">
                  {{
                    dateNow
                      | date
                        : profileDateFormat.medium
                        : undefined
                        : timezoneOffset()
                  }}
                </span>
                <span class="badge bg-black mx-1">
                  {{
                    dateNow
                      | date
                        : profileDateFormat.full
                        : undefined
                        : timezoneOffset()
                  }}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span ngbTooltip="{{ 'PROFILE_TIMEZONE_TT' | translate }}">
                  {{ 'PROFILE_TIME_FORMAT' | translate }}
                </span>
              </td>
              <td>
                <!-- [(ngModel)]="profileTimeFormat"
                [ngModelOptions]="{ standalone: true }" -->
                <select
                  class="form-select form-select-sm form-select-dark"
                  (change)="updateProfileTimeFormat()"
                  [(ngModel)]="profileTimeFormat"
                  formControlName="timeFormat"
                >
                  <option
                    *ngFor="let timeFormat of timeFormats"
                    [selected]="timeFormat.name === profileTimeFormat.name"
                    [ngValue]="timeFormat"
                  >
                    {{ timeFormat.name }}
                  </option>
                </select>
              </td>
              <td>
                <span class="badge bg-black mx-1">
                  {{
                    dateNow
                      | date
                        : profileTimeFormat.short
                        : undefined
                        : timezoneOffset()
                  }}
                </span>
                <span class="badge bg-black mx-1">
                  {{
                    dateNow
                      | date
                        : profileTimeFormat.medium
                        : undefined
                        : timezoneOffset()
                  }}
                </span>
                <span class="badge bg-black mx-1">
                  {{
                    dateNow
                      | date
                        : profileTimeFormat.full
                        : undefined
                        : timezoneOffset()
                  }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="showFeatures" class="d-flex flex-row w-auto">
        <div class="col card m-3" *ngFor="let feature of availableFeatures">
          <h5 class="card-header p-2 form-check form-switch">
            <input
              type="checkbox"
              class="mx-1 form-check-input"
              [checked]="isFeatureSelected(feature)"
              (click)="toggleFeature(feature)"
            />
            <label class="ms-2 form-check-label">{{
              feature | translate
            }}</label>
          </h5>
          <div class="card-body">
            {{ feature + '_DESCRIPTION' | translate }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</form>
