import { Component, OnInit } from '@angular/core';
import { IOrganizationForm } from '@desquare/interfaces';
import { OrganizationFormComponent } from '../organization-form/organization-form.component';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { NgIf } from '@angular/common';
import { FormDialogComponent } from '@desquare/components/common/src/modals/form-dialog.component';

@Component({
  standalone: true,
  imports: [
    FormDialogComponent,
    NgIf,
    LoaderComponent,
    OrganizationFormComponent,
  ],
  selector: 'app-create-organization-dialog',
  templateUrl: './create-organization-dialog.component.html',
  styleUrls: ['./create-organization-dialog.component.scss'],
})
export class CreateOrganizationDialogComponent implements OnInit {
  values?: IOrganizationForm;
  loading!: boolean;
  loaderMessage!: string;
  isFormValid!: boolean;
  isFormPristine!: boolean;

  ngOnInit() {
    this.loading = false;
    this.isFormValid = false;
    this.isFormPristine = true;
  }
}
