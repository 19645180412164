<app-form-dialog
  closeButtonText="CREATE"
  [valid]="isFormValid"
  [pristine]="isFormPristine"
  [values]="values"
  dismissButtonText="CANCEL"
  headerText="CREATE_ORGANIZATION"
>
  <app-loader [message]="loaderMessage" *ngIf="loading"></app-loader>
  <app-organization-form
    [hidden]="loading"
    (valid)="isFormValid = $event"
    (pristine)="isFormPristine = $event"
    (values)="values = $event"
    [enableInput]="true"
  ></app-organization-form>
</app-form-dialog>
