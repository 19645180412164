<form
  [id]="formId"
  [formGroup]="organizationForm"
  (submit)="submitForm($event, organizationForm?.value)"
>
  <div class="mb-0 row">
    <div class="col-md-12 card mb-0">
      <h5 *ngIf="formHeader" class="mb-3 text-uppercase bg-light p-2">
        {{ formHeader | translate }}
      </h5>

      <ng-container *ngIf="enableInput">
        <div class="col-12 form-group">
          <label for="title">{{ 'TITLE' | translate }}*</label>
          <span
            class="error"
            *ngIf="
              organizationForm &&
              !organizationForm.controls['title'].pristine &&
              organizationForm.controls['title'].hasError('required')
            "
          >
            {{ 'TITLE_IS_REQUIRED' | translate }}
          </span>
          <input
            type="text"
            class="form-control form-control-dark"
            formControlName="title"
          />
        </div>
        <div class="col-12 form-group">
          <label for="name">{{ 'NAME' | translate }}*</label>
          <span
            class="error"
            *ngIf="
              organizationForm &&
              !organizationForm.controls['name'].pristine &&
              organizationForm.controls['name'].hasError('required')
            "
          >
            {{ 'NAME_IS_REQUIRED' | translate }}
          </span>
          <span
            class="error me-1"
            *ngIf="
              organizationForm &&
              organizationForm.controls['name'].hasError('minlength')
            "
          >
            {{ 'MIN_LENGTH' | translate: { value: minLength } }}
          </span>
          <span
            class="error me-1"
            *ngIf="
              organizationForm &&
              organizationForm.controls['name'].hasError('maxlength')
            "
          >
            {{ 'MAX_LENGTH' | translate: { value: maxLength } }}
          </span>
          <span
            class="error"
            *ngIf="
              organizationForm &&
              organizationForm.controls['name'].hasError('pattern')
            "
          >
            {{ 'INVALID_CHARACTERS' | translate }}
          </span>
          <input
            type="text"
            class="form-control form-control-dark"
            formControlName="name"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="!enableInput">
        <div class="row">
          <div class="col-12 form-group">
            <label for="title">{{ 'TITLE' | translate }}</label>
            <span>{{ organization?.title || ('NO_DATA' | translate) }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-group">
            <label for="name">{{ 'NAME' | translate }}</label>
            <span>{{ organization?.name || ('NO_DATA' | translate) }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</form>
