import { Component, Input } from '@angular/core';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [ConfirmDialogComponent, TranslateModule],
  selector: 'app-set-owner-dialog',
  template: `<app-confirm-dialog>
    <p
      [innerHTML]="
        'TRANSFER_PROFILE_OWNERSHIP_PROMPT'
          | translate : { user: user, profile: profile }
      "
    ></p>
  </app-confirm-dialog> `,
})
export class SetOwnerDialogComponent {
  @Input() user = 'USER';
  @Input() profile = 'PROFILE';
}
